<script setup lang="ts">
import { clamp } from 'lodash-es'

defineSlots<{
  items: () => any
}>()
const DEFAULT_CAROUSEL_ITEMS = 1
const ITEMS_WITHOUT_SCROLL = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
}

const responsive = useResponsive()

const carouselContainerRef = ref<HTMLElement | null>(null)

const currentCarouselItem = ref(DEFAULT_CAROUSEL_ITEMS)

const carouselElements = computed(() => carouselContainerRef.value?.children)
const carouselItemCount = computed(() => carouselElements.value?.length || 0)

const carouselDotCount = computed(() => {
  // TODO: Calculate how much dots should be displayed based on real item width and screen width

  if (responsive.value.mobile && carouselItemCount.value > ITEMS_WITHOUT_SCROLL.mobile) {
    return carouselItemCount.value
  }

  if (responsive.value.tablet && carouselItemCount.value > ITEMS_WITHOUT_SCROLL.tablet) {
    return carouselItemCount.value - 1
  }

  if (responsive.value.desktop && carouselItemCount.value > ITEMS_WITHOUT_SCROLL.desktop) {
    return carouselItemCount.value - 2
  }

  return 0
})

function checkElementHorizontalVisibility(element: HTMLElement | Element) {
  const { left, right, width } = element.getBoundingClientRect()

  // At least half of left side of element must be visible
  return left <= window.innerWidth && right >= width / 2
}

function handleScroll() {
  if (!carouselElements.value) {
    return
  }

  for (let i = 0; i < carouselElements.value.length; i++) {
    const element = carouselElements.value[i]
    if (checkElementHorizontalVisibility(element)) {
      currentCarouselItem.value = i + 1
      break
    }
  }
}

function scrollToPost(index: number) {
  carouselElements.value?.[index - 1]?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'start',
  })
}

watch(carouselDotCount, () => {
  currentCarouselItem.value = clamp(currentCarouselItem.value, 1, carouselDotCount.value)
})
</script>

<template>
  <div class="flex justify-center">
    <ul
      ref="carouselContainerRef"
      class="hide-scrollbar group my-[-54px] flex max-w-5xl snap-x snap-mandatory flex-nowrap py-[54px] lg:grid lg:grid-cols-12"
      :class="carouselDotCount ? 'overflow-x-auto' : 'overflow-x-visible gap-x-5'"
      @scroll="handleScroll"
    >
      <slot name="items" />
    </ul>
  </div>

  <!-- CAROUSEL DOTS -->
  <div v-if="carouselDotCount" class="mt-6 flex w-full items-center justify-center gap-x-2">
    <button
      v-for="dotIndex in carouselDotCount"
      :key="dotIndex"
      class="size-[10px] rounded-full transition-colors duration-200"
      :class="[dotIndex === currentCarouselItem ? 'bg-brand' : 'bg-grey-200']"
      @click="scrollToPost(dotIndex)"
    />
  </div>
</template>
