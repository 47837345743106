import type { Strapi4RequestParams, Strapi4Response } from '@nuxtjs/strapi'
import { ShortPortPopulate, ShortPostKeys } from './types'
import { apiRequest } from '~/api/helpers/apiRequest'
import type { BlogPost } from '~/composables'
import { simplifyStrapiCollectionResponse } from '~/api/helpers/functions'

export function useHomepageBlogPreview() {
  const posts = useState<BlogPost[] | null>('posts', () => null)

  const fetchPosts = useMemoize(async () => {
    const response = await apiRequest<Strapi4Response<BlogPost>>(
      '/blog-posts',
      {
        pagination: { page: 1, pageSize: 3 },
        populate: Object.values(ShortPortPopulate),
        fields: Object.values(ShortPostKeys),
        sort: ['top:desc', 'createdAt:desc'],
        filters: {
          $and: [
            { top: { $notNull: true } },
          ],
        },
      } satisfies Strapi4RequestParams,
    )

    const fetchedPosts = simplifyStrapiCollectionResponse<BlogPost>(response) || null
    posts.value = fetchedPosts

    return fetchedPosts
  })

  return {
    fetchPosts,
    posts,
  }
}
