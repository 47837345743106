<script setup lang="ts">
const props = defineProps<{
  posts: BlogPost[]
  enableCardShadow?: boolean
}>()

const emit = defineEmits<{
  'data-layer-update': [id: number]
}>()

const posts = toRef(props.posts)
</script>

<template>
  <BaseCarousel>
    <template #items>
      <BaseCarouselItem
        v-for="{ author, createdAt, description, poster, slug, title, id, category } in posts"
        :key="id"
      >
        <template #default>
          <BlogCard
            :author="author?.data"
            :categories="category ? JSON.parse(category) : []"
            class="h-full border-none"
            :class="{
              'shadow-light': enableCardShadow,
            }"
            :created-at="createdAt"
            :description="description"
            :desktop-view-breakpoint="false"
            :poster="poster?.data"
            :slug="slug"
            :title="title"
            title-level="h2"
            variant="small"
            @data-layer-update="emit('data-layer-update', id)"
          />
        </template>
      </BaseCarouselItem>
    </template>
  </BaseCarousel>
</template>
