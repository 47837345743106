<script setup lang="ts">
const responsive = useResponsive()

const cardsWidthMap = {
  mobile: 'w-full',
  tablet: 'w-1/2',
  desktop: '', // On desktop width will be setted by grid
}

const activeBreakpoint = computed(() => Object.entries(responsive.value).find(([_, isActive]) => isActive)?.[0] as keyof typeof responsive.value)

// Giving default value when responsive is not loaded, to prevent layout shifts
const widthClass = computed(() => {
  return cardsWidthMap[activeBreakpoint.value] || 'lg:w-[unset] md:w-1/2 sm:w-1/2 w-full'
})
</script>

<template>
  <li
    class="carousel-item -ml-5 h-full shrink-0 grow-0 snap-start snap-always px-5 first-of-type:ml-0 group-[.overflow-x-visible]:!ml-0 group-[.overflow-x-visible]:!px-0 lg:col-span-4"
    :class="widthClass"
  >
    <slot class="w-full" name="default" />
  </li>
</template>
